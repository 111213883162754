.modal-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #29323D;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    max-width: 380px;
    padding: 40px 20px;
    z-index: 2;

    &__links {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 5px;

        &__link {
            width: 71px;
            height: 71px;
            margin: 0 7px;
            cursor: pointer;

            &_vk {
                background: url("../../../../assets/img/vk.svg") no-repeat;
            }

            &_ok {
                background: url("../../../../assets/img/ok.svg") no-repeat;
            }

            &_gl {
                background: url("../../../../assets/img/gl.svg") no-repeat;
            }

            &_fb {
                background: url("../../../../assets/img/fb.svg") no-repeat;
            }
        }

    }
}