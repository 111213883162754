.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  animation: backgroundIn .3s forwards;
  background-color: rgba(0,0,0,.2);

  &__content {
    //animation: windowIn .3s forwards;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes backgroundIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes windowIn {
  0% {
    transform: translate(-50%, -0%)
  }
  100% {
    transform: translate(-50%, -50%)
  }
}

.react-stars {
  outline: none;
}

