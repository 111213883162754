.VectorMap__Wrapper {
  overflow: hidden;
  max-height: 600px;
  width: 1000px;
  max-width: 100%;
  margin: auto;
  svg {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
}
.Modal {
  padding: 20px;
  max-width: 180px;
  margin-right: 9px;
  //padding: 40px 20px;
  background: rgba(255,255,255,.5);
  border-radius: 3px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 20px;
  right: -6px;
  display: flex;
  cursor: pointer;
  text-align: center;
  .bold {
    color: #fc6e5a;
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
  }
  .small {
    color: #3e3e3e;
  }
  &__Close {
    margin-top: 5px;
  }
}
//
//@media all and (max-width: 640px){
//  .Modal {
//    padding: 5px;
//    max-width: 100px;
//  }
//}
