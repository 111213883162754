.MunicipalityBlock {
  margin: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  color: #124872;
  a {
    text-decoration: none;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .main {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: #eff4fb;
    border-top: 2px solid #124872;
    border-bottom: 2px solid #124872;
    width: 100%;
    padding: 17px 0;
  }
  .icon {
    width: 30px;
  }
  .links {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .link {
    margin: 0 5vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    &-sign {
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    &-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      width: 125px;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #83b5e3;
      color: #fff;
      cursor: pointer;
      height: 37px;
      width: 125px;
      &:hover {
        background: #5881a7;
      }
    }
  }
  .caption {
    font-size: 25px;
    margin-left: 10px;
    font-weight: 500;
  }
  .name {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: .4em;
  }
  .address {
    text-align: center;
  }
}
//
//@media all and (max-width: 640px){
//  .MunicipalityBlock {
//    .links {
//      display: flex;
//      flex-flow: column;
//    }
//    .link {
//      margin-bottom: 20px;
//    }
//  }
//}
