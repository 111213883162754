.header {
  &__bg {
    //background: rgb(81, 139, 204);
    //background: linear-gradient(
    //  0deg,
    //  rgba(81, 139, 204, 1) 0%,
    //  rgba(255, 255, 255, 1) 100%
    //);
  }
  &__wrapper {
    padding: 28px 0;
    height: 350px;
  }
  &__title {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10px;
    font-size: 70px;
    line-height: 79px;
    color: #123d73;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__survey {
    max-width: 1280px;
    margin: 110px auto 0;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      color: #335d8c;
      font-weight: 700;
      width: 230px;
      height: 56px;
      background-color: #ffffff;
      border: 2px solid #335d8c;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.1s ease;

      &:hover {
        filter: drop-shadow(0px 2px 6.5px rgba(58, 106, 161, 0.68));
      }
    }
  }
  &__row {
    display: flex;
    margin: 0 10px;
    &__bg {
      background: #3a6aa1;
    }
    &__item {
      display: flex;
      align-items: center;
      width: 50%;
      &__text {
        margin-left: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #dbefff;
        font-weight: 400;
      }
      .first {
        margin-right: 52px;
      }
    }
  }
  &__organization {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 10px;
    &__links {
      display: flex;
      margin-bottom: 23px;
      border-bottom: 2px solid #335d8c;
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 365px;
        padding: 13px 0;
        text-align: center;
        font-size: 18px;
        color: #335d8c;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        border-left: 2px solid #335d8c;
        border-top: 2px solid #335d8c;
        border-right: 2px solid #335d8c;
      }
      .active {
        background: #335d8c;
        color: #fff;
      }
      img {
        width: 35px;
        margin-right: 10px;
      }
    }
    &__selects {
      display: flex;
      > div {
        flex-grow: 1;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &.second {
        > div {
          &:not(:first-child) {
            .css-1wa3eu0-placeholder {
              color: white;
            }
          }
        }
      }
      // .css-2b097c-container {
      //   .css-1wa3eu0-placeholder {
      //     color: white;
      //   }
      // }
      // .css-1wa3eu0-placeholder {
      //   color: white;
      // }

      //& .css-2b097c-container:nth-child(1) {
      //  width: 205px;
      //  margin-right: 20px;
      //}
      //& .css-2b097c-container:nth-child(2) {
      //  width: 400px;
      //  margin-right: 20px;
      //}
      //& .css-2b097c-container:nth-child(3) {
      //  flex: 1;
      //}
    }

    &__extendedselects {
      display: flex;
      margin-top: 10px;
      width: 100%;
      position: relative;

      &.open {
        // background-color: #f2f2f2;
        height: 60px;
      }

      &.open::after {
        content: '';
        width: auto;
        top: 60px;
      }

      &__text {
        display: inline-block;
        color: #335d8c;
        text-decoration: underline;
        margin: 25px 0px 0px 10px;
        cursor: pointer;
      }

      &__plus {
        display: inline-block;
        position: absolute;
        cursor: pointer;
        height: 30px;
        width: 30px;
        left: 0px;
        top: 20px;
      }
    }

    &__dateSection {
      display: flex;
    }

    &__date {
      display: block;
      margin-top: 50px;
      margin-right: 40px;
    }

    &__range {
      margin-top: -8px;
      p {
        text-align: center;

        b {
          margin-right: 5px;
          font-size: 20px;
        }
        span {
          font-size: 14px;
        }
      }
      input {
        width: 400px;
        margin-top: -15px;
      }

      .disable__range {
        height: 20px;
        cursor: pointer;
        margin-left: 20px;
      }
    }

    &__calendar {
      margin-top: 15px;
      position: relative;
      img {
        position: absolute;
        // height: 35px;
        // width: 35px;
        height: 20px;
        top: 2px;
        left: 2px;
        z-index: 10;
      }
    }
    // .react-datepicker__day--outside-month {
    //   color: #e0e0e0 !important;
    // }

    // &__datepicker {
    //   // height: 35px;
    //   height: 20px;
    //   margin-bottom: 30px;
    //   z-index: 2;
    //   font-size: 15px;
    //   padding-left: 40px;

    //   &:focus {
    //     outline: none;
    //   }
    // }

    &__orientation {
      //margin-top
      margin-top: 30px;
      width: 65%;
    }

    &__group {
      margin-top: 20px;
    }

    &__groupContainer {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &__advisory,
    &__help,
    &__license {
      // margin-top: 30px;
      margin-top: 30px;

      input {
        cursor: pointer;
        margin-right: 12px;
        transform: scale(1.5);
      }
      label {
        position: relative;
        margin-right: 20px;
      }

      &__information {
        position: absolute;
        display: inline-block;
        cursor: pointer;
        top: -6px;
      }

      &__informationText {
        position: relative;
        z-index: 2;

        p {
          position: absolute;

          left: 15px;

          top: 10px;

          font-size: 12px;
          padding: 15px;
          width: 390px;
          outline: 2px solid #dfdfdf;
          border-radius: 5px;
          background-color: #f5f5f5;
        }
      }
    }

    &__quantity {
      font-size: 20px;
      margin-left: 25%;
    }

    &__button {
      // position:absolute;
      display: flex;
      justify-content: end;
      bottom: 0;
      right: 0;
      text-align: center;

      margin-top: 40px;
      margin-bottom: 20px;
      &__clear {
        background-color: #e5e5e5;
        border-radius: 5px;
        width: 150px;
        padding: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      &__apply {
        background-color: #345d8c;
        border-radius: 5px;
        width: 150px;
        padding: 20px;
        color: white;
        margin-right: 40px;
        cursor: pointer;
      }
    }
  }
}
.row {
  &__wrapper {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
  }
}

.extendedSearch {
  position: relative;
  border: 1px solid #dfdfdf;
  padding-left: 50px;
  padding-top: 20px;
}
.cancel {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
//@media all and (max-width: 1495px) {
//  .header {
//    &__wrapper {
//      background-size: cover !important;
//    }
//  }
//}
//@media all and (max-width: 768px) {
//  .header {
//    &__title {
//      font-size: 35px;
//      line-height: 37px;
//      margin-left: 20px;
//    }
//    &__row {
//      flex-flow: column;
//      align-items: center;
//      &__item {
//        width: 100%;
//        margin-bottom: 20px;
//        margin-left: 40px;
//        margin-right: 30px !important;
//        &__img {
//          width: 100px;
//          text-align: center;
//          flex-shrink: 0;
//          img {
//            height: 45px;
//            margin-right: 20px;
//          }
//        }
//        &__text {
//          font-size: 14px;
//        }
//      }
//    }
//    &__organization {
//      &__links {
//        flex-flow: column;
//        align-items: center;
//      }
//      &__selects {
//        flex-flow: column;
//        > div {
//          &:not(:last-child) {
//            margin-right: 5px;
//          }
//          margin: 5px;
//        }
//      }
//    }
//  }
//}
