.suggestions {
  &__ul {
    max-width: 800px;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 400px;
    background: #fff;
    padding: unset;
    &.hidden {
      display: none;
    }
  }
  &__li {
    list-style: none;
    padding: 10px 20px;
    border-bottom:1px solid #cccccc;
    cursor: pointer;
  }
}
