.Balloon {
  font-family: 'Roboto', sans-serif;
  position: absolute;

  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #fff;
  padding: 70px 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  color: #124872;
  font-size: 19px;

  

  &__Name {
    font-family: 'Roboto', sans-serif;
    color: #124872;
    font-size: 15px;
    font-weight: bold;
  }
  &__Close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 5px;
    right: 15px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      left: 20px;
      width: 2px;
      height: 15px;
      transform: rotate(45deg);
      background: #335d8b;
    }
    &:before {
      content: '';
      position: absolute;
      left: 20px;
      width: 2px;
      height: 15px;
      transform: rotate(-45deg);
      background: #335d8b;
    }
  }
  .name {
    text-align: center;
  }
  .block {
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;
    &__name {
      font-weight: bold;
      margin-right: 5px;
    }
    &__content {
      a {
        color: #124872;
      }
    }
  }
  .status {
    margin: 30px 0;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    div {
      padding: 10px 0;
      text-transform: uppercase;
    }
    &__green {
      border-top: 1px solid #359470;
      border-bottom: 1px solid #359470;
      color: #359470;
    }
    &__grey {
      border-top: 1px solid #898989;
      border-bottom: 1px solid #898989;
      color: #898989;
    }
  }
  .btn-comment {
    display: flex;
    position: absolute;
    bottom:5px;
    right: 29px;

    align-items: center;
    justify-content: center;
    background-color: rgb(2, 146, 193);
    color: #ffffff;
    width: 203px;
   
    padding: 15px 20px;
    outline: 0;
    border-radius: 0;
    transition: all, 0.3s, ease, 0s;
    cursor: pointer;

    &:hover {
      background-color: #0ba6d9;
    }
  }
}
//@media all and (max-width: 640px){
//  .Balloon {
//    &__Name {
//      font-size: 10px;
//    }
//    &__Close {
//      top: -20px;
//      &:after {
//        height: 10px;
//      }
//      &:before {
//        height: 10px;
//      }
//    }
//  }
//}
