.organizations {
  max-width: 1280px;

  margin: 0 auto;
  position: relative;
  &__search-wrapper {
    margin-top:25px;
    width: 100%;
    input {
      width: 100%;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 8px;
      //+ button + ul {
      //  display: none;
      //}
      //&:focus {
      //  + button + ul {
      //    display: block;
      //  }
      //}
    }
    button {
      cursor: pointer;
      background: #3a6aa1;
      border: none;
      margin-left: 20px;
      border-radius: 5px;
    }
  }
  .suggestions-wrapper {
    display: flex;
    position: relative;
  }
  &__map-wrapper {
    position: relative;
    width: 100%;
  }

  &__politics {
    position: absolute;
    right: 40px;
    bottom: 0;
    color: #999999;
  }
}

.css-2b097c-container {
  // width: 100%;
}

.ScaleControl {
  position: absolute;
  left: calc(50% - 150px);
  top: 20px;
  width: 300px;
  text-align: center;
  background: #f2f2f2;
  padding: 20px 40px;
  box-sizing: border-box;
  color: #393333;
  font-size: 15px;
}

//@media all and (max-width: 768px) {
//  .organizations {
//    &__search-wrapper {
//      margin: 5px;
//    }
//  }
//}
