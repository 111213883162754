.container {
  min-width: 1280px;
  position: absolute;
  display: flex;

  bottom: -50px;
}

.point {
  padding: 5px;
  margin-right: 10px;
  &__color {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 20px;
  }
}
