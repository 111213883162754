.modal-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #347afa;
    font-size: 25px;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
    max-width: 380px;
    padding: 65px 90px;
    z-index: 2;
}