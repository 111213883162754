.citiesandregions {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px 50px 10px;
}

.information {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #124872;
}

.tableWrap {
  display: block;
  max-width: 100%;
  // border-bottom: 1px solid black;
  table {
    width: 100%;
    border-spacing: 0;
    thead {
      tr {
        background: #eff4fb;
        font-size: 16px;
        line-height: 22px;
        color: #124872;
        font-weight: 400;
      }
      th {
        vertical-align: initial;
        padding: 20px 20px;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr:nth-last-of-type(odd) {
      background: #eff4fb;
    }
    tbody tr {
      font-size: 35px;
      line-height: 22px;
      color: #124872;
      font-weight: 400;
      td {
        padding: 20px 0;
      }
      td:nth-child(1) {
        font-size: 16px;
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      // border-bottom: 1px solid black;
      // border-right: 1px solid black;
      text-align: center;
      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
}
