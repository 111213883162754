//.table {
//  display: grid;
//  grid-template-columns: repeat(4, 1fr);
//  //height: 340px;
//  &-cell {
//    padding: 10px 20px;
//    font-size: 16px;
//    font-family: 'Roboto', sans-serif;
//    color: #124872;
//    text-align: center;
//    &--color {
//      background: #f2f2f4;
//    }
//    &--data {
//      font-size: 35px;
//    }
//    &--star {
//      position: relative;
//      background: #eff4fb;
//      &::after {
//        position: absolute;
//        content: '';
//        width: 10px;
//        height: 8px;
//        background: url("../../assets/img/star.png") no-repeat right;
//        background-size: contain;
//      }
//    }
//    &:first-child {
//      background: #eff4fb;
//    }
//  }
//}
table {
  font-family: 'Roboto', sans-serif;
  color: #124872;
  text-align: center;
  th {
    font-size: 16px;
    font-weight: 400;
    background: #eff4fb;
  }
  tr:nth-child(2n + 1) {
    background: #f2f2f4;
  }
  td.data {
    font-size: 35px;
  }
}
.star {
  color: #8fbce6;
}
.notice {
  margin-top: 10px;
  display: flex;
  font-family: 'Roboto', sans-serif;
  color: #124872;
  // font-size: 16px;
  // font-weight: 400;
  img {
    height: 10px;
    margin-right: 5px;
  }
}
//@media all and (max-width: 768px) {
//  .table {
//    &-cell {
//      font-size: 12px;
//      padding: 5px;
//    }
//  }
//  .notice {
//    font-size: 12px;
//  }
//}
