$text-blue: #124872;
$blue: #335d8c;

.modal-comment {

    max-height: 100vh;
    width: 1000px;
    text-align: center;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    //border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    outline: none;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    overflow-y: auto;
    z-index: 3;

    &__header {
        padding: 20px 60px;
        border-bottom: 1px solid $blue;

        &__title {
            font-size: 30px;
            line-height: 38px;
            color: $text-blue;
            font-weight: 400;
        }
    }

    &__body {
        position: relative;

        .visit {
            display: flex;
            justify-content: center;
            padding: 10px 0;

            font-size: 15px;
            line-height: 24px;
            color: #124872;
            font-weight: 700;

            &__label {
            }

            &__is-visit {
                width: 50px;
            }

            &__switch {
                position: relative;
                cursor: pointer;
                padding: 5px 0;

                input {
                    appearance: none;
                    display: none;
                }

                span {
                    position: relative;
                    display: block;
                    width: 36px;
                    height: 18px;
                    background-color: #ff6666;
                    border-radius: 18px;
                    margin-top: -2px;
                }

                .indicator {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
                    transition: 0.3s;
                }

                input:checked ~ .indicator {
                    left: 18px;
                }

                input:checked ~ span {
                    background-color: #599d5d;
                }
            }
        }

        .rating {
            background-color: #eff4fb;
            padding: 10px 50px;

            &__label {
                font-size: 15px;
                line-height: 24px;
                color: $text-blue;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            &__grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                &__col {
                    margin: 0 7px 14px;

                    &__caption {
                        font-size: 15px;
                        line-height: 20px;
                        color: #ffffff;
                        font-weight: 400;
                        background-color: #3a6aa1;
                        padding: 10px 25px;
                        height: auto;
                    }
                    &__stars {
                        display: flex;
                        justify-content: center;
                        background-color: #ffffff;
                        box-sizing: border-box;
                        border: 1px solid #dcdcdc;
                        border-top: none;
                        padding: 5px;

                        & img {
                            width: 20px;
                            height: 20px;
                            margin: 0 3px;
                        }
                    }
                }
            }
        }

        .comment {
            display: grid;
            grid-template-columns: 1fr 5fr;
            padding: 10px 40px;

            &__left {

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &__avatar {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background-color: #cccccc;
                    background-size: cover;
                }

                &__name {
                    font-size: 15px;
                    line-height: 18px;
                    color: #124872;
                    font-weight: 400;
                    margin-top: 10px;
                }
            }

            &__right {
                position: relative;
                margin-left: 30px;
                text-align: left;

                &__caption {
                    font-size: 15px;
                    line-height: 18px;
                    color: #124872;
                    font-weight: 400;
                }

                &__text-area {
                    border-width: 1px;
                    border-color: #cccccc;
                    border-style: solid;
                    background-color: #ffffff;
                    color: #555;
                    width: calc(100% - 40px);
                    height: 70px;
                    resize: none;
                    border-radius: 0;
                    line-height: 1.5;
                    margin-top: 5px;
                    padding: 10px;
                    outline: none;

                    &:focus {
                        border-color: #333;
                    }

                    &__left-chars {
                        position: absolute;
                        right: 30px;
                        top: 92px;
                        font-size: 15px;
                        line-height: 18px;
                        color: #c3c3c3;
                        font-weight: 400;
                    }
                }

                &__add-photo {
                    font-size: 16px;
                    line-height: 18px;
                    color: #41647e;
                    font-weight: 500;
                    display: block;
                    text-align: right;

                    span {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        top: 20px;
                        right: 20px;
                        cursor: pointer;
                        white-space: nowrap;
                        text-align: right;

                        &:after {
                            content: '';
                            position: absolute;
                            left: -20px;
                            width: 2px;
                            height: 20px;
                            background: #335d8b;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            left: -20px;
                            width: 2px;
                            height: 20px;
                            transform: rotate(90deg);
                            background: #335d8b;
                        }
                    }

                    input {
                        display: none;
                        appearance: none;
                    }
                }

                &__uploaded-images {
                    img {
                        position: relative;
                        height: 60px;
                        width: 60px;
                        margin-right: 22px;
                    }

                    span {
                        position: relative;
                        cursor: pointer;
                        &::after {
                            content: '';
                            background: url("../../../../assets/img/close.svg");
                            position: absolute;
                            top: -61px;
                            right: 6px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .submit {
            display: flex;
            justify-content: center;
            margin: 10px 0 15px;

            &__button {
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                font-weight: 700;
                background-color: $blue;
                text-transform: uppercase;
                padding: 10px 20px;
                cursor: pointer;
            }
        }

        .error {
            position: absolute;
            width: 100%;
            font-size: 18px;
            line-height: 24px;
            color: #333333;
            font-weight: 600;
            background-color: #ffcccc;
            padding: 31px 0;
            margin-bottom: 30px;
            display: none;
            z-index: 1;

            &_active {
                display: block;
                animation: errorIn 4s forwards;
            }
        }
    }
}

@keyframes errorIn {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}