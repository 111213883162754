.Popup {
  pointer-events: none;
  color: #123d73;
  background: #fff;
  width: 300px;
  padding: 20px 15px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
  border-radius: 5px;
  .sign {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    //padding-bottom: 15px;
  }
  .list {
    border-top: 1px solid #9fc9ee;
    padding-top: 15px;
    margin-top: 15px;
    .li {
      padding: 5px 0;
      .bold {
        font-weight: bold;
      }
    }
  }
}
//@media all and (max-width: 640px){
//  .Popup {
//    width: auto;
//    padding: 5px;
//    .sign {
//      font-size: 10px;
//    }
//  }
//}
